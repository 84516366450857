import React, { useState } from 'react';

export const RadioButton = ({
  onClick
}) => {
  const [selectedOption, setSelectedOption] = useState("option1")

  const handleRadioChange = (option) => {
    setSelectedOption(option)
    onClick(option)
  }

  return (
    <div>
      <div className='rdo'>
        <input type="radio" id="option1" name="options" value="option1" checked={selectedOption === "option1"} onChange={() => handleRadioChange("option1")} />
        <label for="option1">Login as a guest </label>
      </div>
      <div className='rdo'>
        <input type="radio" id="option2" name="options" value="option2" checked={selectedOption === "option2"} onChange={() => handleRadioChange("option2")} />
        <label for="option2">Login as Customer </label>
      </div>
    </div>
  );
};