const mobRegex = /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[789]\d{9}$/;

const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

const regString = /\d/;

export const ValidationRules = (rule, value) => {
  let error = '';
  if (rule?.required) {
    if (
      value === ''
        || value === null
        || value === undefined
        || (Array.isArray(value) && value.length === 0)
    ) {
      error = `${rule.field_name} is Required`;
    }
  }

  if (rule?.type === 'number' && value) {
    if (!Number(value)) {
      error = 'Please Enter only digits';
    } else {
      error = `${rule.field_name} is Required`;
    }
  }
  if (rule?.type === 'mobile' && value && !mobRegex.test(value)) {
    error = 'Please enter correct mobile.';
  }
  if (rule?.type === 'email' && value && !validateEmail(value)) {
    error = 'Please enter correct email.';
  }

  if (rule?.type === 'onlyString' && value && regString.test(value)) {
    error = 'Please enter only alphabets.';
  }

  return error;
};
