export const loginFields = [
    {
        field_name: 'Email',
        field_key: 'email',
        type: 'email',
        required: true,
    },
    {
        field_name: 'Mobile Number',
        field_key: 'phone',
        type: 'string',
        required: true,
    },
]