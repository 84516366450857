import React from 'react';

export const errorChecker = (errObj) => Object.keys(errObj).find((key) => {
    if (errObj[key] !== '') {
        return true;
    }
}) || '';

export const showError = (error) => {
  if (error) {
    return (
      <div className='show-error' style={{ color: '#f33535', fontSize: '12px' }}>{error}</div>
    );
  }
};
