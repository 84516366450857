import React from 'react';
import { image } from "../../common/Theme";
import { Header } from "../../component/Header";
import { useNavigate } from 'react-router-dom';
import BasicDateTimeRangePicker from '../../component/BasicDateTimeRangePicker';


const BookingSummary = () => {
    const navigate = useNavigate();

    const handleSubmit = async () => {
        navigate('/payment-details');
    }
    const handleSubmit1 = async () => {
        navigate('/booking-create');
    }
   
    return (
        <>


            <div className="booking-main" style={{
                backgroundImage: `url(${image.bodybg})`,
            }}>
                <div className="booking-inner">

                    <Header />

                    <div className="booking-inner-content">
                        <div className="row ">
                            <div className="col-lg-6">
                                <div className="inner-right ">
                                    <h2>Booking Summary</h2>
                                    <>
                                       
                                        <div className="booking-summry">
                                            <div className="summry-box">
                                                <h4>Drop Off Date &amp; Time</h4>
                                                <span>09 Sept 2024 | 17:45</span>
                                            </div>
                                            <div className="summry-box">
                                                <h4>Pick Up Date &amp; Time</h4>
                                                <span>12 Sept 2024 | 17:45</span>
                                            </div>
                                            <div className="summry-box">
                                                <h4>License Plat No.</h4>
                                                <span>NY 1234 HJ</span>
                                            </div>
                                            <div className="summry-box">
                                                <h4>Vehicle Type </h4>
                                                <span>Sedan</span>
                                            </div>
                                            <div className="summry-box">
                                                <h4>Total Duration</h4>
                                                <span>For 4 days</span>
                                            </div>
                                            <div className="summry-box">
                                                <h4>Total Amount</h4>
                                                <span>$ 189.56</span>
                                            </div>
                                            <div className="summry-box">
                                                <h4>Reservation Fee</h4>
                                                <span>$25 non-refundable</span>
                                            </div>
                                            <div className="summry-box">
                                                <p>Total amount have to pay at the location.</p>
                                             </div>
                                            <div className="summry-box">
                                              <p>Please note, trucks and others vehicles are also accepted with additional charges</p>
                                            </div>
                                            <div className="submit-btn">
                                                <button className="accept-btn" onClick={handleSubmit}>Accept</button>
                                                <button className="cancel-btn" onClick={handleSubmit1}>Cancel</button>
                                            </div>
                                          
                                        </div>
                                    </>

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="inner-left">
                                    <img src={image.bigcar} alt="" className="car-imgg" />
                                    <img src={image.dotscircle} alt="" className="rotate-dots" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default BookingSummary;