// userSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null, // Initial state for user
};

const userSlice = createSlice({
  name: 'user',
  initialState: {...initialState},
  reducers: {
    setUser: (state, action) => {
      state.user = {...action.payload};
    },
    logout: (state) => ({ ...initialState })
  },
});

export const { setUser, logout } = userSlice.actions;
export default userSlice.reducer;
