import React, { useEffect, useState } from "react";
import { image } from "../../common/Theme";
import { Header } from "../../component/Header";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import { format } from 'date-fns';
// import AdapterDateFns from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Apiconnection from '../../utils/Apiconnection';
import { toast } from "react-toastify";
import { Modal } from '@mui/material';
import moment from "moment";

// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';


const BookingCreate = () => {

  const location = useLocation();
  const { email, password, customerId, firstName, lastName, phone } = location.state || {};
  console.log('Customer_payload', email, password, customerId, firstName, lastName, phone);
  const payload = location.state?.payload;
  console.log('payload', payload);

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [policies, setPolicies] = useState('');
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [email2, setEmail2] = useState(payload?.email);
  const [fname, setfname] = useState(payload?.firstname);
  const [lname, setlname] = useState(payload?.lastname);


  const [startDate, setstartDate] = useState(null)
  const [endDate, setendDate] = useState(null)
  const [licensePlateNumber, setLicensePlateNumber] = useState("");
  const [slotId, setSlotId] = useState('');
  const [slotName, setSlotName] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  // const { customerId } = location.state || {};
  const [mobileNumber2, setMobileNumber2] = useState(payload?.password);

  const handleCheckboxChange = async (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      await fetchPolicies();
      setOpenTermsModal(true);
    } else {
      setTermsAgreed(false);
    }
  };

  const fetchPolicies = async () => {
    try {
      const response = await Apiconnection.post('get-policies');
      if (response?.data?.status) {
        const fetchedPolicies = response?.data?.data?.policies || '';
        setPolicies(fetchedPolicies);
      } else {
        toast.error('Failed to fetch policies');
      }
    } catch (error) {
      console.error('Error fetching policies:', error);
      toast.error('Failed to fetch policies');
    }
  };

  const handleAcceptTerms = () => {
    setTermsAgreed(true);
    setOpenTermsModal(false);
  };

  const handleCloseModal = () => {
    setOpenTermsModal(false);
    setTermsAgreed(false);
  };


  const EndDateHandle = () => {

    handleSlotCheck();
  }



  const handleSlotCheck = async () => {
    const payload = {
      checkinDate: moment(startDate).format(('YYYY-MM-DD')),
      startTime: moment(startDate).format(('HH:mm:ss')),
      checkoutDate: moment(endDate).format(('YYYY-MM-DD')),
      endTime: moment(endDate).format(('HH:mm:ss')),
    };

    try {
      const response = await Apiconnection.post("decide-slot", payload);
      if (response.data.status) {
        setSlotId(response.data.data.bookedSlot.id);
        setSlotName(response.data.data.bookedSlot.slotName);
        setSubmitDisabled(false);
        toast.success("Slot available");
      } else {
        setSubmitDisabled(true);
        toast.error("Slot not available");
      }
    } catch (error) {
      console.error("Error checking slot availability:", error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = {};

    if (!licensePlateNumber) {
      toast.error("Please enter a license plate number")
    } else if (!termsAgreed) {
      toast.error("Please agree to the terms and conditions")
    } else {

      let payload = {
        bookingPlatform: "customerPanel",
        logInType: customerId ? "Customer" : "Guest",
        customerId: customerId ? customerId : "",
        vehicleTypeId: "72",
        dialCode: '+1',
        slotId: slotId,
        firstName: customerId ? firstName : fname,
        lastName: customerId ? lastName : lname,
        email: customerId ? email : email2,
        phone: customerId ? phone : mobileNumber2,
        plateNumber: licensePlateNumber,
        fromDate: moment(startDate).format('YYYY-MM-DD'),
        selectedCheckInTime: moment(startDate).format(('HH:mm:ss')),
        toDate: moment(endDate).format('YYYY-MM-DD'),
        selectedCheckOutTime: moment(endDate).format(('HH:mm:ss')),
        passengerCount: "4",
      };

      try {
        const response = await Apiconnection.post('booking-request-create', payload);
        if (response?.data?.status) {
          const bookingRequestId = response.data.data.id;
          const calculateBookingChargesPayload = {
            bookingRequestId,
            vehicleTypeId: "72",
            passengerCount: "4",
            checkinDate: moment(startDate).format('YYYY-MM-DD'),
            startTime: moment(startDate).format(('HH:mm:ss')),
            checkoutDate: moment(endDate).format('YYYY-MM-DD'),
            endTime: moment(endDate).format(('HH:mm:ss')),
          };
          const calculateBookingChargesResponse = await Apiconnection.post('calculate-booking-charges-before-booking', calculateBookingChargesPayload);
          if (calculateBookingChargesResponse?.data?.status) {
            toast.success('Booked Successfully!');
            const bookingDetails = {
              invoiceCreate: calculateBookingChargesResponse.data.data?.invoiceCreate,
              bookingRequestDetail: calculateBookingChargesResponse?.data?.data?.bookingRequestDetail,
              customerid: payload?.customerId
            };
            navigate('/booking-details', { state: bookingDetails });
            console.log("bookingDetails", bookingDetails)
          } else {
            toast.error('Failed to calculate booking charges!');
          }
        } else {
          toast.error('Failed to create booking!');
        }
      } catch (error) {
        console.error('Error adding vehicle data:', error);
        toast.error('Failed to create booking!');
      }
    }
  };


  return (
    <div
      className="booking-main"
      style={{
        backgroundImage: `url(${image.bodybg})`,
      }}
    >
      <div className="booking-inner">
        <Header />

        <div className="booking-inner-content">
          <div className="row ">
            <div className="col-lg-6">
              <div className="inner-right ">
                <h2>Book Your Parking</h2>
                <div className="login-form-sec">

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="custom-form-group mb-4">

                        <span className="checkin-calender">
                          <img src={image.calender} alt="" />
                        </span>

                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setstartDate(date)}
                          minDate={new Date()}
                          peekNextMonth
                          placeholderText="Select Start Time"
                          className="form-control input-style"
                          showTimeSelect
                          timeFormat="h:mm aa"
                          timeIntervals={5}
                          timeCaption="time"
                          dateFormat="dd-MM-yyyy h:mm aa"
                        />


                      </div>
                      <div className="custom-form-group mb-4">
                        <span className="checkin-calender">
                          <img src={image.calender} alt="" />
                        </span>

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setendDate(date)}
                          onCalendarClose={EndDateHandle}
                          minDate={startDate}
                          peekNextMonth
                          placeholderText="Select End Time"
                          className="form-control input-style"
                          showTimeSelect
                          timeFormat="h:mm aa"
                          timeIntervals={5}
                          timeCaption="time"
                          dateFormat="dd-MM-yyyy h:mm aa"
                          disabled={startDate !== null ? false : true}
                        />


                      </div>
                      <div className="custom-form-group mb-4">
                        <span className="checkin-calender">
                          <img src={image.vehicle} alt="" />
                        </span>

                        <input
                          type="text"
                          className="form-control"
                          maxLength={10}
                          placeholder="Enter Plate Number"
                          value={licensePlateNumber}
                          onChange={(e) =>
                            setLicensePlateNumber(e.target.value)
                          }
                        />


                      </div>
                    </div>

                    <div className="col-lg-12 mb-3">
                      <div class="guest-login bk-log rdo">
                        <input
                          type="checkbox"
                          id="agreed"
                          name="terms"
                          value="agreed"
                          checked={termsAgreed}
                          onChange={handleCheckboxChange}
                        />
                        <label for="agreed">
                          I Agreed With Terms & Conditions
                        </label>
                      </div>
                      {errors.terms && (
                        <div className="error">{errors.terms}</div>
                      )}
                    </div>

                    <div className="form-group col-lg-12 ">
                      <button
                        onClick={handleSubmit}
                        className="mb-3 btn btn_primary"
                        type="button"
                        disabled={!termsAgreed}
                        title={!termsAgreed ? "Please accept the T&C" : ""}
                        style={{
                          backgroundColor: !termsAgreed ? 'lightblue' : 'blue',
                          color: !termsAgreed ? 'gray' : 'white',
                          cursor: !termsAgreed ? 'not-allowed' : 'pointer',
                          border: '1px solid lightgray',
                          padding: '10px 20px',
                          borderRadius: '5px',
                        }}
                      >
                        CONTINUE{" "}
                      </button>
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="inner-left">
                <img src={image.bigcar} alt="" className="car-imgg" />
                <img src={image.dotscircle} alt="" className="rotate-dots" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openTermsModal}
        onClose={handleCloseModal}
        aria-labelledby="terms-modal"
        aria-describedby="terms-modal-description"
      >
        <div className="terms-modal" style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          border: '1px solid gray',
          borderRadius: '5px',
          maxHeight: '400px',
          overflowY: 'auto'
        }}>
          <div id="terms-modal-description" dangerouslySetInnerHTML={{ __html: policies }} />
          <hr />
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={handleAcceptTerms}>
              Accept
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BookingCreate;