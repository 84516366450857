import React, { useEffect, useState } from "react";
import { Table, TableBody, Modal, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem, Button, Toolbar, Typography, FormControl, InputLabel, TablePagination, FormHelperText } from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Apiconnection from "../../utils/Apiconnection";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import BookingHeader from '../../../src/component/BookingHeader';

const BookingList = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [listType, setListType] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [openModal, setOpenModal] = useState(false)
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [totalBookings, setTotalBookings] = useState(0);
    const [agentList, setAgentList] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [errors, setErrors] = useState({});

    const [openCheckInModal, setOpenCheckInModal] = useState(false);
    const [checkInForm, setCheckInForm] = useState({
        actualvehicleTypeId: "",
        actualFromDate: "",
        actualCheckInTime: "",
        slotId: "",
        plateNumber: ""
    });

    const location = useLocation();
    const { email, password, customerId, firstName, lastName, phone, payload } = location.state || {};


    const [openCheckOutModal, setOpenCheckOutModal] = useState(false);
    const [checkOutForm, setCheckOutForm] = useState({
        bookingRequestId: '',
        actualToDate: dayjs().format('YYYY-MM-DD'),
        actualCheckOutTime: dayjs().format('HH:mm')
    });

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleListTypeChange = (event) => {
        setListType(event.target.value);
    };

    const handleRequestMarkReady = async (bookingId) => {
        const payload = {
            bookingRequestId: bookingId
        };

        try {
            const response = await Apiconnection.post('request-to-mark-ready', payload);

            if (response.data.status) {
                const updatedBookings = bookings.map((booking) => {
                    if (booking.id === bookingId) {
                        return { ...booking, bookingStatus: "Ready" };
                    }
                    return booking;
                });
                setBookings(updatedBookings);
                fetchBookings(page + 1, rowsPerPage); // Fetch the updated bookings
                // toast.success('Booking marked as Ready successfully!');
            } else {
                // toast.error(response.data.message);
            }
        } catch (error) {
            // console.error('Error marking booking as ready:', error);
            // toast.error('Failed to mark booking as Ready.');
        }
    };


    const handleOpenModal = () => {
        console.log('Email:', email);
        console.log('Password:', password);
        console.log('Customer ID:', customerId);
        navigate('/booking-create', { state: { email, password, customerId, firstName, lastName, phone, payload } });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const navigate = useNavigate();

    const handleCheckIn = (hasReservation) => {
        if (hasReservation) {
            navigate('/customer-booking');
        } else {
            navigate('/add-booking');
        }
        setOpenModal(false);
    };

    const fetchVehicleTypes = async () => {
        try {
            const response = await Apiconnection.post('vehicle-type-list');
            if (response.data.status) {
                setVehicleTypes(response.data.data);
            } else {
                // toast.error(response.data.message);
            }
        } catch (error) {
            // console.error('Error fetching vehicle types:', error);
            // toast.error('Failed to fetch vehicle types.');
        }
    };

    const handleViewInvoice = async (bookingRequestId) => {
        console.log('Navigating to invoice with ID:', bookingRequestId); // Debugging log
        navigate('/booking-receipt', { state: { bookingRequestId } }); // Pass the bookingId in state
    };

    useEffect(() => {
        const token = localStorage.getItem('prime_access_token');

        // If token exists, set it in the API connection
        if (token) {
            Apiconnection.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            // Optionally redirect to login if token is not available
            navigate('/login');
        }
        getAgentListApi();
        fetchVehicleTypes();
    }, []);

    const fetchBookings = async (page, limit) => {
        try {
            const response = await Apiconnection.post(`customer-booking-history?page=${page}&limit=${limit}`);
            if (response.data.status) {
                setBookings(response.data.data);
                setFilteredBookings(response.data.data);
                setTotalBookings(response.data.data.length);
                console.log(filteredBookings?.length)
            } else {
                // toast.error(response.data.message);
            }
        } catch (error) {
            // console.error('Error fetching bookings:', error);
            // toast.error('Failed to fetch bookings.');
        }
    };

    useEffect(() => {
        fetchBookings(page + 1, rowsPerPage);
    }, [page, rowsPerPage]);

    const shouldDisableDate = (date) => {
        return date.isAfter(dayjs());
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const totalPages = Math.ceil(filteredBookings.length / rowsPerPage);

    const getAgentListApi = async () => {
        try {
            const response = await Apiconnection.post('agent-list');
            console.log('agent-list', response)
            if (response?.data?.status) {
                setAgentList(response.data.data.agentList);
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            // console.error('Error fetching Agent list:', error);
            // toast.error('Failed to fetch Agent list.');
        } finally {
        }
    };

    const handleProfileClick = () => {
        // Navigate to the My Profile page
        navigate('/my-profile'); // Adjust the route as necessary
    };

    const handleChangePasswordClick = () => {
        // Navigate to the Change Password page
        navigate('/change-password'); // Adjust the route as necessary
    };

    const paginatedBookings = bookings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    return (
        <div className='grid-container'>
            <BookingHeader
                username={firstName}
                onProfileClick={handleProfileClick}
                onChangePasswordClick={handleChangePasswordClick}
            />
            <div className='inner-container'>
                <div className='custom-table'>
                    {/* <Toolbar>
                        <Typography variant="h6" style={{ flex: 1 }}>
                            Booking List
                        </Typography>
                        <Button variant="contained" className="action-btn" onClick={handleOpenModal}>
                            ADD NEW BOOKING
                        </Button>
                    </Toolbar> */}
                    <Toolbar>
                        <Typography variant="h6" style={{ flex: 1 }}>
                            Booking List
                        </Typography>
                        <Button variant="contained" className="action-btn" onClick={handleOpenModal}>
                            ADD NEW BOOKING
                        </Button>
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="add-user-modal"
                            aria-describedby="add-user-modal-description">

                            <div className="booking-modal" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '0px', border: '1px solid gray', borderRadius: '5px' }}>
                                <h2 id="modal-title">Check In</h2>
                                <h6>Does the Customer have a reservation?</h6>
                                <div id="modal-description">
                                    <Button variant="contained" className="yes-btn" color="primary" onClick={() => handleCheckIn(true)}>Yes</Button>
                                    <Button variant="contained" className="no-btn" color="secondary" onClick={() => handleCheckIn(false)}>No</Button>
                                </div>
                            </div>
                        </Modal>

                    </Toolbar>

                    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Ticket ID</TableCell>
                                    <TableCell>Customer Details</TableCell>
                                    <TableCell>Slot</TableCell>
                                    <TableCell>Start Date & Time</TableCell>
                                    <TableCell>End Date & Time</TableCell>
                                    <TableCell>Action</TableCell>
                                    <TableCell>Invoice</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredBookings && filteredBookings.length > 0 ? (
                                    filteredBookings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((booking) => (
                                        <TableRow key={booking.id}>
                                            <TableCell>{booking.bookingTicketNo}</TableCell>
                                            <TableCell>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#ccc',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginRight: '10px',
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                        color: '#fff'
                                                    }}>
                                                        {booking.user.firstName.charAt(0).toUpperCase() + booking.user.lastName.charAt(0).toUpperCase()}
                                                    </div>
                                                    <span>{booking.user.firstName} {booking.user.lastName}<br></br>{booking.customerMobile}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>{booking.slot.slotName}</TableCell>
                                            <TableCell>
                                                <div>
                                                    <div>{booking.fromDate} | {booking.selectedCheckInTime}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <div>{booking.toDate} | {booking.selectedCheckOutTime}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant="contained" color="primary" onClick={() => handleRequestMarkReady(booking.id)} sx={{ fontSize: 12 }}
                                                    disabled={booking.bookingStatus !== "Checked In" || booking.readyStatus === "Requested" || booking.readyStatus === "Ready"}>


                                                    {booking.readyStatus === "Not Requested" ? "Request Ready" : booking.readyStatus === "Requested" ? "Requested" : "Request Accepted"} </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button onClick={() => handleViewInvoice(booking.id)} variant="contained" color="primary" sx={{ fontSize: 12 }}>
                                                    View Invoice
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">No bookings found.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* <TablePagination
                        rowsPerPageOptions={[5, 15, 25]}
                        component="div"
                        count={filteredBookings.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        nextButtonProps={{ disabled: page >= totalPages - 1 }}
                        backButtonProps={{ disabled: page === 0 }}
                    /> */}
                </div>

            </div>
        </div>
    );
};

export default BookingList;
