import React from 'react';
// import { BsFillBellFill, BsFillEnvelopeFill, BsPersonCircle, BsSearch, BsJustify } from 'react-icons/bs';
import { image } from "../../src/common/Theme";
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'bootstrap';

function BookingHeader({ OpenSidebar }) {
    const location = useLocation();
    const navigate = useNavigate();

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log(userInfo)

    const handleLogout = () => {
        localStorage.removeItem('prime_access_token');
        navigate('/login');
    };


    let pageTitle = '';
    switch (location.pathname) {
        case '/dashboard':
            pageTitle = 'Dashboard';
            break;
        case '/users':
            pageTitle = 'User Management';
            break;
        case '/bookings':
            pageTitle = 'Booking';
            break;
        case '/payments':
            pageTitle = 'Payments';
            break;
        case '/policies':
            pageTitle = 'Policy';
            break;
        case '/reports':
            pageTitle = 'Policy';
            break;
        case '/vendors':
            pageTitle = 'Vendor Management';
            break;
        case '/agents':
            pageTitle = 'Agent Management';
            break;
        case '/parking-slots':
            pageTitle = 'Parking Slots';
            break;
        case '/rate-chart':
            pageTitle = 'Rate Chart';
            break;
        case '/notifications':
            pageTitle = 'Notification';
            break;

        case '/car-movements':
            pageTitle = 'Car Movements';
            break;

        case '/revenue-report':
            pageTitle = 'Revenue Report';
            break;

        case '/add-booking':
            pageTitle = 'Booking';
            break;
        case '/customer-booking':
            pageTitle = 'Booking';
            break;
        case '/booking-details':
            pageTitle = 'Booking';
            break;
        case '/booking-receipts':
            pageTitle = 'Booking';
            break;

        default:
            pageTitle = '';
    }

    return (
        <header className='header'>
            <div className='menu-icon'>
                {/* <BsJustify className='icon' onClick={OpenSidebar} /> */}
            </div>
            <div className='header-left'>
                <h3>{pageTitle}</h3>
            </div>
            <div className='header-right'>
                <div className='user-details' style={{ fontSize: '16px', fontWeight: '500' }}>
                    <img src={image.userimage} alt="" /><b>Welcome</b> {userInfo?.firstName} / &nbsp;
                    <a style={{ color: 'blue', cursor: 'pointer' }} variant="contained" color="secondary" onClick={handleLogout}>
                        Logout
                    </a>
                </div>
                <a href="#" className='header-notification'><img src={image.notificationbell} alt="" /></a>
            </div>
        </header>
    );
}

export default BookingHeader;