import React from "react";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/js/custom.js";
import { BrowserRouter as Router, Routes, Route, HashRouter } from "react-router-dom";
import PrivateRoute from "./component/Routes/PrivateRoute";
import BookingCreate from "./views/Booking/BookingCreate";
import BookingDetails from "./views/Booking/BookingDetails";
import Login from "./views/Login/Login";
import BookingSummary from "./views/Booking/BookingSummary.js";
import BookingList from "./views/Booking/BookingList.js";
import MakePayment from "./views/Booking/MakePayment.js";
import CustomerResgistration from "./views/Booking/CustomerResgistration";
import BookingReceipt from "./views/Booking/BookingReceipt.js";


 
function App() {
  return (
    <HashRouter>
    {/* <Router> */}
      <Routes>
      <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path='/' element={<PrivateRoute />}> */}
            <Route path='/booking-create' element={<BookingCreate />} />
            <Route path='/booking-summary' element={<BookingSummary />} />
            <Route path='/booking-list' element={<BookingList />} />
            <Route path='/booking-receipt' element={<BookingReceipt />} />
            <Route path='/payment-details' element={<MakePayment />} />
            <Route path='/booking-details' element={<BookingDetails />} />
            <Route path='/customer-registration' element={<CustomerResgistration />} />
        {/* </Route> */}
      </Routes>
    {/* </Router> */}
    </HashRouter>
  );
}
 
export default App;