const url = `${window.location.origin}/`

// const url = `${window.location.origin}/customer`


export const image = {
    logo: url + '/images/logo.png',
    user: url + '/images/user.png',
    bg: url + '/images/login-bg.png',
    car: url + '/images/car-bg.png',
    address: url + '/images/address.png',
    mail: url + '/images/mail.png',
    bigcar: url + '/images/big-car.png',
    dotscircle: url + '/images/dots-circle.png',
    calender: url + '/images/calender.png',
    vehicle: url + '/images/vehicle.png',
    checked: url + '/images/checked.png',
    download: url + '/images/download.png',
    bodybg: url + '/images/bg-dotted.png',
    greenbg: url + '/images/green-bg.png',
    email: url + '/images/email.png',
    lock: url + '/images/lock.png',
    }