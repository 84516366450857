import React from 'react';
import { image } from "../../common/Theme";
import { Header } from "../../component/Header";
import { useNavigate } from 'react-router-dom';
import { CountdownCircleTimer } from "react-countdown-circle-timer";



const MakePayment = () => {
    const navigate = useNavigate();

    const handleSubmit = async () => {
        navigate('/booking-details');
    }

    const RenderTime = ({ remainingTime }) => {
        return (
            <div className="timer-text">
                <span>{remainingTime}</span>
                <span>seconds</span>
            </div>
        );
    }

    const handleTimerComplete = () => {
        navigate('/booking-details');
    }

    return (
        <>


            <div className="booking-main" style={{
                backgroundImage: `url(${image.bodybg})`,
            }}>
                <div className="booking-inner">

                    <Header />

                    <div className="booking-inner-content">
                        <div className="row ">
                            <div className="col-lg-6">
                                <div className="inner-right ">
                                    <h2>Make Payment</h2>
                                    <>

                                        <div className="booking-summry">

                                            <div className="timer-wrapper">
                                                <CountdownCircleTimer
                                                    isPlaying
                                                    duration={10}
                                                    colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                                    colorsTime={[10, 6, 3, 0]}
                                                    onComplete={handleTimerComplete}
                                                >
                                                    {RenderTime}
                                                </CountdownCircleTimer>
                                            </div>


                                            <div className="summry-box">
                                                <h4>Total Amount</h4>
                                                <span>$ 189.56</span>
                                            </div>
                                            <div className="summry-box">
                                                <p>$25 non-refundable</p>
                                            </div>
                                            <div className="summry-box">
                                                <p>Please note, trucks and others vehicles are also accepted with additional charges</p>
                                            </div>
                                            <div className="submit-btn">
                                                <button className="accept-btn" onClick={handleSubmit}>Pay</button>
                                            </div>

                                        </div>
                                    </>

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="inner-left">
                                    <img src={image.bigcar} alt="" className="car-imgg" />
                                    <img src={image.dotscircle} alt="" className="rotate-dots" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default MakePayment;