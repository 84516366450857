import axios  from "axios";
import React, { useState, useEffect } from "react";

const URL = process.env.REACT_APP_BASE_URL;
let API_Token = localStorage.getItem("prime_access_token");

export default axios.create({
    baseURL:URL,
    headers: { 
        'Content-Type': 'application/json', 
          'token': API_Token,
        'X-Requested-With': 'XMLHttpRequest', 
    },
   
})