/* eslint-disable no-restricted-syntax */
import { ValidationRules } from './rules';

const Validation = (states, rules) => {
  const errorObj = {};

  for (const [property, value] of Object.entries(states)) {
    const ruleObj = rules.find((obj) => obj.field_key === property);

    if (ruleObj?.required) {
      const isNotValid = ValidationRules(ruleObj, value);
      if (isNotValid) errorObj[property] = isNotValid;
    }
  }

  return errorObj;
};

export default Validation;