// src/FlagDropdown.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

const FlagDropdown = () => {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    const fetchFlags = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const flagOptions = response.data.map(country => ({
          value: country.cca2.toLowerCase(),
          label: (
            <img
              src={country.flags.svg} // Use `svg` for higher quality
              alt={country.cca2}
              style={{ width: 24, height: 16, marginRight: 8 }} // Adjust size as needed
            />
          ),
        }));

        setOptions(flagOptions);

        // Set the default value to the US flag
        const defaultOption = flagOptions.find(option => option.value === 'us');
        setSelectedValue(defaultOption);
      } catch (error) {
        console.error('Error fetching flags:', error);
      }
    };

    fetchFlags();
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
  };

  return (
    <Select
      options={options}
      onChange={handleChange}
      value={selectedValue} // Set the selected value here
      placeholder="Select a flag"
      styles={{
        option: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
        }),
        singleValue: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
        }),
      }}
    />
  );
};

export default FlagDropdown;