import React, { useEffect, useRef, useState } from "react";
import { image } from "../../common/Theme";
import { Header } from "../../component/Header";
import { useLocation, useNavigate } from "react-router-dom";
import html2pdf from 'html2pdf.js';
import Apiconnection from '../../utils/Apiconnection';
import { toast } from "react-toastify";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const BookingDetails = () => {
  const location = useLocation();
  const [bookingDetails, setBookingDetails] = useState(location.state);
  const [customerdetails, setCustomerdetails] = useState(location.state.customerdetails);
  const [policies, setPolicies] = useState('');
  const [specificPolicy, setSpecificPolicy] = useState('');
  console.log('payload', bookingDetails);
  console.log('customerdetailspayload', customerdetails);

  const navigate = useNavigate();
  const printableRef = useRef();

  useEffect(() => {
    if (location.state) {
      setBookingDetails(location.state);
      console.log('payload', bookingDetails);
      setCustomerdetails(location.state.customerdetails);
      console.log('Customer ID:', location.state.customerdetails?.customerId);
      
    }
  }, [location.state]);

    // Calculate total days
    const fromDate = new Date(bookingDetails?.bookingRequestDetail?.fromDate);
    const toDate = new Date(bookingDetails?.bookingRequestDetail?.toDate);
    const totalDays = Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24));

    const fetchPolicies = async () => {
      try {
        const response = await Apiconnection.post('get-policies');
        if (response?.data?.status) {
          const fetchedPolicies = response?.data?.data?.policies || '';
          setPolicies(fetchedPolicies);
          extractSpecificPolicy(fetchedPolicies);
        } else {
          toast.error('Failed to fetch policies');
        }
      } catch (error) {
        console.error('Error fetching policies:', error);
        toast.error('Failed to fetch policies');
      }
    };

    const extractSpecificPolicy = (policiesString) => {
      // Split policies by line breaks or HTML tags to find the second point
      const policyLines = policiesString.split(/<\/?p>/).filter(line => line.trim() !== '');
      if (policyLines.length >= 2) {
        // Extract the second policy and remove any special characters and leading numbers
        const secondPolicy = policyLines[1].replace(/<[^>]+>/g, '').trim(); // Remove HTML tags
        const cleanedPolicy = secondPolicy.replace(/^\d+\.\s*/, ''); // Remove leading number and dot
        setSpecificPolicy(cleanedPolicy);
      }
    };

    useEffect(() => {
      fetchPolicies();
    }, []);
    
    const handlePrint = () => {
      const element = printableRef.current;
      const opt = {
          margin:       1,
          filename:     'booking_receipt.pdf',
          image:        { type: 'jpeg', quality: 0.98 },
          html2canvas:  { scale: 2 },
          jsPDF:        { unit: 'in', format: 'A4', orientation: 'portrait' }
      };

      html2pdf().from(element).set(opt).save();
  };

    // Google Maps settings
    const mapContainerStyle = {
      height: "400px",
      width: "100%"
    };
  
    const center = {
      lat: parseFloat(bookingDetails?.bookingRequestDetail?.latitude) || 0, // Replace with actual latitude
      lng: parseFloat(bookingDetails?.bookingRequestDetail?.longitude) || 0 // Replace with actual longitude
    };

  return (
    <div
      className="booking-main"
      style={{
        backgroundImage: `url(${image.bodybg})`,
      }}
    >
      <div className="booking-inner">
        <Header />

        <div className="booking-inner-content">
          <div className="row ">
            <div className="col-lg-4">
              <div className="inner-right ">
                <h2 className="confim-heading">
                  <img src={image.checked} alt="" /> Booking Confirmed
                </h2>

                <div className="confirm-booking" ref={printableRef}>
                  <div className="booking-id">
                    <p>Booking ID</p>
                    <div className="download-booking">
                      <h2>{bookingDetails?.bookingRequestDetail?.bookingTicketNo}</h2>
                      <div className="download-invoice">
                        {/* <a href="#">
                          <img src={image.email} alt="" />
                        </a> */}
                        <a >
                          <img src={image.download} onClick={handlePrint} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="drop-details">
                    <p>Drop Off Date & Time</p>
                    <h4>{bookingDetails?.bookingRequestDetail?.fromDate} | {bookingDetails?.bookingRequestDetail?.selectedCheckInTime}</h4>
                  </div>

                  <div className="pick-details">
                    <p>Pick Up Date & Time</p>
                    <h4>{bookingDetails?.bookingRequestDetail?.toDate} | {bookingDetails?.bookingRequestDetail?.selectedCheckOutTime}</h4>
                  </div>

                  <div className="vehicle-details">
                    <div className="vehicle-plat">
                      <p>License Plat No.</p>
                      <h4>{bookingDetails?.bookingRequestDetail?.plateNumber}</h4>
                    </div>
                    <div className="vehicle-type">
                      <p>Vehicle Type</p>
                      <h4>Sedan</h4>
                    </div>
                  </div>
                  <h4 className="time-count">For {totalDays} days</h4>
                  <h4 className="amount-count">Amount to be paid at location $ {bookingDetails?.invoiceCreate?.payableAmount}<span className="note">*</span></h4>
                  <div className="policies-details mt-4">
                    {/* <h4>Policy:</h4> */}
                    {/* <p><span className="note" style={{fontSize: '20px'}}>*</span> {specificPolicy}</p> */}
                    <p><span className="note" style={{fontSize: '20px'}}>*</span> 
                    Extra charges may be applicable for parking fees after voucher expiration,
                    canceled reservations, extra passengers (additional charges will apply on passengers above four.) additional time beyond the reservation, early check-in,
                    oversized vehicle and towing fees if keys are not left with the attendant.
                    </p>
                  </div>
                </div>


              {bookingDetails?.customerid === '' && (
                <div>
                  <button 
                    onClick={() => navigate('/customer-registration')} 
                    style={{
                      padding: '10px 20px',
                      marginTop: '-10px',
                      backgroundColor: '#4CAF50',
                      color: 'white',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer'
                    }}>
                    Go to Customer Registration
                  </button>
                </div>
              )}

                
              </div>
            </div>
            <div className="col-lg-8">
              <div className="confirm-car">
                <img src={image.greenbg} alt="" className="grn-bg" />
                {/* <img src={image.bigcar} alt="" className="car-rotate" /> */}
                <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={1}
                  >
                    <Marker position={center} />
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;